import "./about.css";
import hotel from "../../assets/Hotel.svg";
import ftevent from  "../../assets/Ft_Event.svg"
import ftevent2 from  "../../assets/Ft2_Event.svg"


function About() {
  return (
    <>
      <section className="About">
        <h3 style={{ marginBottom: 20 }} className="title-about">
          Sobre o Festival
        </h3>
        <p className="descrip-about">
        O Brasília Tango Festival é o primeiro evento internacional de Tango de Brasília que oferece ao público e participantes um mergulho nessa icônica dança Argentina. Com programação de oficinas (aulas), Mostras Coreográficas e Milongas (bailes), o evento promove o contato e a experiência com a cultura argentina por meio da dança.
        </p>
        <p className="descrip-about">
        A atmosfera contagiante das apresentações e atividades do Festival se firmam no intercâmbio cultural de profissionais brasileiros e argentinos que proporcionará aos amantes dessa arte, dias de muito conhecimento e entretenimento.
        </p>
        <p className="descrip-about">
        O Brasília Tango Festival tem como objetivo principal proporcionar oportunidade de aperfeiçoamento técnico aos artistas de dança com um festival cultural descentralizado, proporcionando à população uma oportunidade de fomento e ampliação do conhecimento cultural, posicionando Brasília como referência em eventos de tango de alcance internacional. Também visa promover intercâmbio cultural entre Brasil e Argentina por meio da dança, impulsionando a geração de empregos, renda e contribuindo para o desenvolvimento do turismo local.
        </p>
        <p className="descrip-about">
        O BTF é um evento de dança que busca a valorização do Tango como ação de transformação social dos participantes, além de proporcionar apresentações para o público em geral, o Festival sustenta o título do 1º Festival de Tango do Centro Oeste, dando oportunidade para dançarinos de todo o Brasil a participarem do evento por está localizado no coração do Brasil. 
        </p>
        <p className="descrip-about">
        Desde sua primeira edição, realizada em 2020, o Festival segue em uma crescente de público lançando Brasília no circuito de grandes eventos de Tango do país. Além de Brasília, Goiânia, Uberlândia, Cuiabá, Rio de Janeiro, Recife, Fortaleza, Manaus, Londrina, Curitiba marcaram presença e fortaleceram a capital brasileira como referência da comunidade tangueira.
        </p>
      </section>
      {/* <section className="About">
        <h3 style={{ marginBottom: 20 }} className="title-about">
          Hotel parceiro
        </h3>
        <img className="hotel" src={hotel} />
        <h3 style={{ marginBottom: 20 }} className="title-about">
          Tarifas especiais
        </h3>

        <div class="table">
          <div class="row">
            <div class="header">Tipo de apartamento</div>
            <div class="header">Diária especial</div>

          </div>
          <div class="row">
            <div class="cell">Single</div>
            <div class="cell">R$ 273/diária</div>


          </div>
          <div class="row">
            <div class="cell">Duplo Twin ou Casal</div>
            <div class="cell">R$ 303/diária</div>

          </div>
          <div class="row">
            <div class="cell">Triplo - 3 camas de solteiro</div>
            <div class="cell">R$ 333/diária</div>

          </div>
        </div>


        <p className="descrip-hotel">
          *Incluso café da manhã no restaurante do hotel
        </p>
      </section> */}


      {/* <a
        href="https://wa.me/+5561984840055?text=Quero%20participar%20do%20Bras%C3%ADlia%20Tango%20Festival%20"
        target="_blank"
        className="button-ingress-value"
      >
        FALE COM A ORGANIZAÇÃO
      </a> */}

      <h3 style={{ marginBottom: 20 }} className="title-about">
          Confira as fotos do evento
      </h3>
      <section style={{ marginBottom: 120 }} className="container-img">
        <img className="ft-event" src={ftevent}/>
        <img className="ft-event" src={ftevent2}/>
      </section>


    </>


  );
}

export default About;
