
import papper from "../../assets/PapperPrice.svg"
import papperlote from "../../assets/PapperPriceLote.svg"
import papper2 from "../../assets/PapperPrice2.svg"
import papper3 from "../../assets/PapperPrice3.svg"
import papper4 from "../../assets/PapperPrice4.svg"
import button from "../../assets/Button_FalarOrganização.png";
import "./newprice.css";

function NewPrice() {
  return (
    <section className="Value">
      <section className="all-ingress-values">
        <div className="container-ingressprincp">
          <img className="img-papper" src={papper} />
          <ul className="list-ingress">
            <p className="lote-ingress">FULL PASS</p>
            <li className="text-lote">12 horas de aula</li>
            <li className="text-lote">4 noites de Milonga com show</li>
            <li className="text-lote">Espetáculo no teatro com Orquestra ao vivo</li>
            <p className="price-ingress"></p>
            <p className="finaly-lote"><br/></p>
            <p className="finaly-lote2"></p>
          </ul>
        </div>
        <div className="container-ingresslote">
        <img className="img-papperlote" src={papperlote} />
          <ul className="list-ingress-lote">
            <p className="price-ingress" style={{ width: "50%" }}>R$ 800</p>
            <li className="text-lote" style={{ width: "50%" }}>1º lote válido até 10/01/2025</li>
            <p className="price-ingress" style={{ width: "50%" }}>R$ 950</p>
            <li className="text-lote" style={{ width: "50%" }}>2º lote válido de 10/01/2025 a 10/03/2025</li>
            <p className="price-ingress" style={{ width: "50%" }}>R$ 1150</p>
            <li className="text-lote" style={{ width: "50%", marginBottom: "20px" }}>3º lote válido após 10/03/2025</li>
            <p className="finaly-lote"></p>
            <p className="finaly-lote2"></p>
          </ul>
        </div>
        <div className="container-ingress" >
        <img className="img-papper" src={papper2} />
          <ul className="list-ingress3">
            <p className="lote-ingress">MILONGA PASS </p>
            <li className="text-lote">4 noites de Milongas com show</li>
            <p className="price-ingress">R$ 395</p>
          </ul>
        </div>
        <div className="container-ingress" >
        <img className="img-papper" src={papper3} />
          <ul className="list-ingress2">
            <p className="lote-ingress">WEEKEND PASS</p>
            <li className="text-lote">2 dias de aula</li>
            <li className="text-lote">2 Milongas</li>
            <p className="sub-dresc-ingress">Os 2 dias são de sua escolha</p>
            <p className="price-ingress">R$ 585</p>
          </ul>
        </div>
        <div className="container-ingress">
        <img className="img-papper" src={papper4} />
          <ul className="list-ingress-vip">
            <p className="lote-ingress">PACOTE VIP SPONSOR</p>
            <li className="text-lote">Full Pass</li>
            <li className="text-lote">Acento especial no espetáculo </li>
            <li className="text-lote">Brinde com os artistas no camarim do teatro</li>
            <li className="text-lote">Assento reservado nas Milongas</li>
            <li className="text-lote">1 garrafa de espumante </li>
            <li className="text-lote">1 souvenir da Griffe do Festival </li>
            <li className="text-lote">20 % de desconto em sapatos ou roupas do evento</li>
            <p className="price-ingress-vip">R$ 1500</p>
          </ul>
        </div>
      </section>
      <div className="conteiner-button">
        <a
          href="https://wa.me/+5561984840055?text=Quero%20participar%20do%20Bras%C3%ADlia%20Tango%20Festival%20"
          target="_blank"
          className="button-ingress-value"
        >
          <img src={button} style={{ width: "60%"}}/>
        </a>
      </div>
    </section>
  );
}

export default NewPrice;
