import { useState, useEffect } from "react";
import logoPranchetaMain from "../../assets/BTF_logoPranchetaMain.svg";
import detalheSite from "../../assets/DetalheSite.svg";
import "./start.css";

function Start() {
  const [isBoxVisible, setIsBoxVisible] = useState(false);

  const handleButtonClick = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  useEffect(() => {
    handleButtonClick();
  }, []);

  return (
    <section style={{ marginBottom: 100 }} id="anchor_event" className="Start">
        <img className="detalhesite" src={detalheSite}/>
      <div className={`box ${isBoxVisible ? "slide-in" : "slide-out"}`}>
        <img
          style={{ marginTop: 130, marginBottom: 10, maxWidth: "100%" }}
          src={logoPranchetaMain}
          alt="Logo Prancheta Main"
        />
        <p className="date-rosa">13 a 16 de março de 2025</p>
      </div>
      <div className="names-artists">
        <div>
          <p className="text-color-start contNames">CLARISA ARAGÓN &</p>
          <p className="text-color-start">JONATHAN SAAVEDRA</p>
        </div>
        <div>
          <p className="text-color-start contNames">ANNA ELISA &</p>
          <p className="text-color-start">ANDRÉ CARVALHO</p>
        </div>
        <div>
          <p className="text-color-start contNames">YASMINA MAMANA &</p>
          <p className="text-color-start">MÜLLER DANTAS</p>
        </div>
        <div>
          <p className="text-color-start contNames">CYNTHIA PALÁCIOS &</p>
          <p className="text-color-start">SEBASTIAN BOLIVAR</p>
        </div>

      </div>
    </section>
  );
}

export default Start;
