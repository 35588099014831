import './App.css';
import About from './components/about/about';
import Artists from './components/artists/artists';
import Dates from './components/dates/dates';
import Footer from './components/footer/footer';
import Header from './components/header/Header';
import Start from './components/start/start';
import NewPrice from './components/newprices/newprice';
// import imageCamisa1 from './assets/camisa1.svg'
// import imageCamisa2 from './assets/camisa2.svg'
// import imageCamisa3 from './assets/camisa3.svg'
// import imageCamisa4 from './assets/camisa4.svg'
// import imageCamisa5 from './assets/camisa5.svg'
import MeuCarousel from './components/carrossel/primeCarousel';
import Loc from './components/loc/loc';
import { useState } from 'react';
import ShowTango from './components/showtango/showTango';

function App() {
  const [selectPage, setSelectPage] = useState(0)
  const ShowPage = () => {
    if(selectPage === 0) {
      return (
        <>
      <Start/>
      <Artists/>
      <NewPrice/>
      <About/>
        </>
      )
    } else{
      return(
      <>
      <ShowTango/>
      </>
      )
    }
  } 
  return (
    <div className="App">
      <Header setSelectPage = {setSelectPage} />
      {ShowPage()}
    </div>
  );
}

export default App;
